export const ROLES_CONSTANT = {
  ADMIN: "ADMIN",
  SUBADMIN: "SUBADMIN",
  USER: "USER",
  MANUFACTURER: "MANUFACTURER/IMPORTER",
  DISTRIBUTOR: "DISTRIBUTOR",
  DEALER: "DEALER",
  APPROVERS: "APPROVERS",
  RETAILER:"RETAILER",
  CONTRACTOR:"CONTRACTOR"
}
